import { db, functions } from "@/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
const getDefaultState = () => {
  return {
    order: {},
    orders: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async deliverOrder({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "orders", id);
      await updateDoc(docRef, {
        delivered: true,
      });

      commit("setSuccess", "order_delivered");
      commit("setDelivered", id);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getOrder({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "orders", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const order = docSnap.data();
        order.id = docSnap.id;
        commit("setOrder", order);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getOrders({ commit }, companyId) {
    try {
      commit("wait");
      const getOrders = httpsCallable(functions, "getOrders");
      const res = await getOrders({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setOrders", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDelivered(state, orderId) {
    state.order.delivered = true;
    const index = state.orders.findIndex((o) => o.id == orderId);

    if (index != -1) {
      const order = state.orders[index];
      state.products.splice(index, 1, order);
    }
    state.waiting = false;
  },
  setOrder(state, order) {
    state.order = order;
    state.waiting = false;
  },
  setOrders(state, orders) {
    state.orders = orders;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const order = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default order;
