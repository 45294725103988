import { db } from "@/firebase";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const getDefaultState = () => {
  return {
    partner: {},
    partners: [],
    status: {},
    types: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getEstatePartners({ commit }, partnerIds) {
    try {
      commit("wait");
      const q = query(
        collection(db, "partners"),
        where(documentId(), "in", partnerIds)
      );
      const querySnapshot = await getDocs(q);
      const partners = querySnapshot.docs.map((doc) => {
        const partner = doc.data();
        partner.id = doc.id;
        return partner;
      });

      commit("setPartners", partners);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPartner({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "partners", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const partner = docSnap.data();
        partner.id = docSnap.id;
        commit("setPartner", partner);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPartners({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "partners"));
      const partners = querySnapshot.docs.map((doc) => {
        const partner = doc.data();
        partner.id = doc.id;
        return partner;
      });
      commit("setPartners", partners);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getTypes({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "types"));
      const types = querySnapshot.docs.map((doc) => {
        const type = doc.data();
        type.id = doc.id;
        return type;
      });
      commit("setTypes", types);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setPartner(state, partner) {
    state.partner = partner;
    state.waiting = false;
  },
  setPartners(state, partners) {
    state.partners = partners;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setTypes(state, types) {
    state.types = types;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getPartner(state) {
    return state.partner;
  },
};

const partner = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default partner;
