import { db, functions } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    company: {},
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const getCompanyData = async (id) => {
  try {
    const docRef = doc(db, "companies", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    if (data) {
      data.id = id;
    }
    return data;
  } catch (error) {
    return {
      error: error,
    };
  }
};

const actions = {
  async getCompany({ commit }, id) {
    try {
      commit("wait");
      const companyData = await getCompanyData(id);

      if (companyData.error) {
        commit("failure", companyData.error);
      } else {
        commit("setCompany", companyData);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateCompanyTheme({ commit }, data) {
    try {
      commit("wait");
      commit("setCompany", {});
      const updateCompanyTheme = httpsCallable(functions, "updateCompanyTheme");
      const res = await updateCompanyTheme(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "company_updated");
      commit("setCompany", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCompany(state, company) {
    state.company = company;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getCompany(state) {
    return state.company;
  },
  getLicenceValidity(state) {
    return new Date() <= new Date(state.company.licenceValidity);
  },
};

const company = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default company;
