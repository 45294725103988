<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.no_licence") }}</h1>
    <div class="card p-4">{{ t("text.no_licence") }}</div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
