import { db, functions } from "@/firebase";
import router from "@/router";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    project: {},
    projects: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async addPhaseTask({ commit }, { taskId, projectId, phaseName }) {
    try {
      commit("wait");
      const data = state.project;
      const match = data.phases.find((p) => p.name == phaseName);
      if (match.taskIds) {
        data.phases.find((p) => p.name == phaseName).taskIds.push(taskId);
      } else {
        data.phases.find((p) => p.name == phaseName).taskIds = [];
        data.phases.find((p) => p.name == phaseName).taskIds.push(taskId);
      }
      const docRef = doc(db, "projects", projectId);
      //await updateDoc(docRef, { phases: arrayRemove });
      //await updateDoc(docRef, { phases: arrayUnion() });
      const project = data;
      commit("setSuccess", "project_updated");
      commit("replaceProject", project);
      router.go(-1);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async createPhase({ commit }, { data, projectId }) {
    try {
      commit("wait");
      const docRef = doc(db, "projects", projectId);
      await updateDoc(docRef, { phases: arrayUnion(data) });
      const project = data;
      commit("setSuccess", "phase_created");
      commit("replaceProject", project);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createProject({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "projects"), data);
      const project = data;
      project.id = docRef.id;

      commit("setSuccess", "project_created");
      commit("addProject", project);
      router.push("/projects");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getProject({ commit }, projectId) {
    try {
      commit("wait");
      const getProject = httpsCallable(functions, "getProject");
      const res = await getProject({ projectId });

      if (!res.data.success) throw "unknown";

      commit("setProject", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getNotificationProject({ commit }, notificationId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "projects"),
        where("requestId", "==", notificationId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const projects = querySnapshot.docs.map((doc) => {
        const project = doc.data();
        project.id = doc.id;
        return project;
      });

      commit("setProject", projects[0]);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getProjects({ commit }, companyId) {
    try {
      commit("wait");
      const getProjects = httpsCallable(functions, "getCompanyProjects");
      const res = await getProjects({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setProjects", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getLatestProjects({ commit }, companyId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "projects"),
        where("companyId", "==", companyId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      const projects = querySnapshot.docs.map((doc) => {
        const project = doc.data();
        project.id = doc.id;
        return project;
      });

      commit("setProjects", projects);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateProject({ commit }, { data, projectId }) {
    try {
      commit("wait");
      const docRef = doc(db, "projects", projectId);
      await updateDoc(docRef, data);
      const project = data;
      commit("setSuccess", "project_updated");
      commit("replaceProject", project);
      router.push("/projects/" + projectId);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addProject(state, project) {
    state.projects.push(project);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceProject(state, project) {
    const index = state.projects.findIndex((b) => b.id == project.id);
    if (index != -1) {
      state.projects.splice(index, 1, project);
    }
    state.project = project;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setProject(state, project) {
    state.project = project;
    state.waiting = false;
  },
  setProjects(state, projects) {
    state.projects = projects;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getProject(state) {
    return state.project;
  },
};

const project = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default project;
