import { db, functions } from "@/firebase";
import router from "@/router";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
const getDefaultState = () => {
  return {
    product: {},
    products: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createProduct({ commit }, data) {
    try {
      commit("wait");
      const createProduct = httpsCallable(functions, "createProduct");
      const res = await createProduct(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "product_created");
      commit("addProduct", res.data.data);
      router.push(`/products/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getProduct({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "products", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const product = docSnap.data();
        product.id = docSnap.id;
        commit("setProduct", product);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getProducts({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "products"));
      const products = querySnapshot.docs.map((doc) => {
        const product = doc.data();
        product.id = doc.id;
        return product;
      });
      commit("setProducts", products);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateProduct({ commit }, data) {
    try {
      commit("wait");
      const updateProduct = httpsCallable(functions, "updateProduct");
      const res = await updateProduct(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "product_updated");
      commit("replaceProduct", res.data.data);
      router.push(`/products/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addProduct(state, product) {
    state.products.push(product);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceProduct(state, product) {
    const index = state.products.findIndex((p) => p.id == product.id);

    if (index != -1) {
      state.products.splice(index, 1, product);
    }
    state.product = product;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setProduct(state, product) {
    state.product = product;
    state.waiting = false;
  },
  setProducts(state, products) {
    state.products = products;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getProduct(state) {
    return state.product;
  },
};

const product = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default product;
