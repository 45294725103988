import { createStore } from "vuex";
import authentication from "./modules/authentication.store";
import bulletin from "./modules/bulletin.store";
import company from "./modules/company.store";
import document from "./modules/document.store";
import estate from "./modules/estate.store";
import file from "./modules/file.store";
import key from "./modules/key.store";
import notification from "./modules/notification.store";
import order from "./modules/order.store";
import partner from "./modules/partner.store";
import product from "./modules/product.store";
import project from "./modules/project.store";
import role from "./modules/role.store";
import task from "./modules/task.store";
import user from "./modules/user.store";

export default createStore({
  state: {},
  mutations: {},
  actions: {
    clearAll({ commit }) {
      commit("authentication/resetState");
      commit("bulletin/resetState");
      commit("company/resetState");
      commit("document/resetState");
      commit("estate/resetState");
      commit("file/resetState");
      commit("key/resetState");
      commit("notification/resetState");
      commit("order/resetState");
      commit("partner/resetState");
      commit("product/resetState");
      commit("project/resetState");
      commit("role/resetState");
      commit("task/resetState");
      commit("user/resetState");
    },
  },
  modules: {
    authentication,
    bulletin,
    company,
    document,
    estate,
    file,
    key,
    notification,
    order,
    partner,
    product,
    project,
    role,
    task,
    user,
  },
});
