import { db, functions } from "@/firebase";
import router from "@/router";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    bulletin: {},
    bulletins: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createBulletin({ commit }, data) {
    try {
      commit("wait");
      const createBulletin = httpsCallable(functions, "createBulletin");
      const res = await createBulletin(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "bulletin_created");
      commit("addBulletin", res.data.data);
      router.push("/bulletins");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getBulletin({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "bulletins", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const bulletin = docSnap.data();
        bulletin.id = docSnap.id;
        commit("setBulletin", bulletin);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getBulletins({ commit }, companyId) {
    try {
      commit("wait");
      const getBulletins = httpsCallable(functions, "getCompanyBulletins");
      const res = await getBulletins({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setBulletins", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getLatestBulletins({ commit }, companyId) {
    try {
      commit("wait");
      const getLatestBulletins = httpsCallable(
        functions,
        "getLatestCompanyBulletins"
      );
      const res = await getLatestBulletins({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setBulletins", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async sendBulletin({ commit }, data) {
    try {
      commit("wait");
      const sendBulletin = httpsCallable(functions, "sendBulletin");
      const res = await sendBulletin(data);

      if (!res.data.success) throw "unknown";

      commit("setBulletin", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateBulletin({ commit }, data) {
    try {
      commit("wait");
      const updateBulletin = httpsCallable(functions, "updateBulletin");
      const res = await updateBulletin(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "bulletin_updated");
      commit("replaceBulletin", res.data.data);
      router.push("/bulletins/" + data.bulletinId);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addBulletin(state, bulletin) {
    state.bulletins.push(bulletin);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceBulletin(state, bulletin) {
    const index = state.bulletins.findIndex((b) => b.id == bulletin.id);
    if (index != -1) {
      state.bulletins.splice(index, 1, bulletin);
    }
    state.bulletin = bulletin;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setBulletin(state, bulletin) {
    state.bulletin = bulletin;
    state.waiting = false;
  },
  setBulletins(state, bulletins) {
    state.bulletins = bulletins;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getBulletin(state) {
    return state.bulletin;
  },
};

const bulletin = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default bulletin;
