import { auth, db, functions } from "@/firebase";
import i18n from "@/i18n";
import router from "@/router";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    status: {},
    user: {},
    waiting: false,
  };
};

const state = getDefaultState();

const getUserData = async (user) => {
  try {
    if (!user) return null;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    if (data) {
      data.id = user.uid;
      data.email = user.email;
    }
    return data;
  } catch (error) {
    return {
      error: error,
    };
  }
};

const hasToReset = (idToken) => {
  return idToken.claims.password_reset ? true : false;
};

const isAdmin = (idToken) => {
  return idToken.claims.admin;
};

const isManager = (idToken) => {
  return idToken.claims.manager_admin || idToken.claims.manager_basic
    ? true
    : false;
};

const actions = {
  async activate({ commit, dispatch }, data) {
    try {
      commit("wait");
      const activateUser = httpsCallable(functions, "activateUser");
      const res = await activateUser(data);

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      const user = auth.currentUser;
      commit("setSuccess", "activated");
      dispatch("signIn", { username: user.email, password: data.password });
    } catch (error) {
      commit("failure", error);
    }
  },
  async changeEmail({ commit }, data) {
    try {
      commit("wait");
      const changeEmail = httpsCallable(functions, "changeEmail");
      const res = await changeEmail(data);
      if (!res.data.success) {
        commit("failure", "email_change_failed");
        return;
      }

      commit("setSuccess", "email_sent");
      commit("done");
    } catch (error) {
      commit("failure", error);
    }
  },
  async changePassword({ commit }, data) {
    try {
      commit("wait");
      const changePassword = httpsCallable(functions, "changePassword");
      const res = await changePassword(data);
      if (!res.data.success) {
        commit("failure", "password_change_failed");
        return;
      }

      commit("setSuccess", "email_sent");
      commit("done");
    } catch (error) {
      commit("failure", error);
    }
  },
  async deleteAvatar({ commit }) {
    try {
      commit("wait");
      const deleteAvatar = httpsCallable(functions, "deleteAvatar");
      const res = await deleteAvatar();
      if (!res.data.success) {
        commit("failure", "profile_image_delete_failed");
        return;
      }
      commit("setSuccess", "profile_updated");
      commit("setUser", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async initializeState({ commit, dispatch }, user) {
    if (state.user.id) return;
    try {
      const userData = await getUserData(user);
      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setUser", userData);
        dispatch("company/getCompany", userData.companyId, { root: true });
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async sendPasswordReset({ commit }, email) {
    try {
      await sendPasswordResetEmail(auth, email);
      commit("setSuccess", "resetEmail");
    } catch (error) {
      commit("failure", error.code.split("/")[1]);
    }
  },
  async signIn({ commit, dispatch }, { username, password }) {
    try {
      commit("wait");
      const { user } = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );

      const idTokenResult = await user.getIdTokenResult();

      if (!isManager(idTokenResult) || isAdmin(idTokenResult)) {
        dispatch("signOut");
      }
      if (hasToReset(idTokenResult)) router.push("/complete");

      const userData = await getUserData(user);

      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setUser", userData);
        dispatch("company/getCompany", userData.companyId, { root: true });
        router.push("/");
      }
    } catch (error) {
      commit("failure", error.code.split("/")[1]);
    }
  },
  async signOut({ commit, dispatch }) {
    try {
      commit("wait");
      await auth.signOut();
      router.push("/sign-in");
      dispatch("clearAll", null, { root: true });
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateUser({ commit }, data) {
    try {
      const user = auth.currentUser;
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, data);

      const userData = await getUserData(user);

      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setSuccess", "profile_updated");
        commit("setUser", userData);
        router.push("/profile");
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateAvatar({ commit }, data) {
    try {
      commit("wait");
      const user = auth.currentUser;
      const updateAvatar = httpsCallable(functions, "updateAvatar");
      const res = await updateAvatar(data);
      if (!res.data.success) {
        commit("failure", "profile_image_update_failed");
        return;
      }

      const userData = await getUserData(user);
      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setSuccess", "profile_updated");
        commit("setUser", userData);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async verifyEmail({ commit }, token) {
    try {
      commit("wait");
      const verifyEmail = httpsCallable(functions, "verifyEmail");
      const res = await verifyEmail({ token });
      if (!res.data.success) {
        commit("failure", "verification_failed");
        return;
      }

      commit("setSuccess", "email_updated");
      commit("done");
    } catch (error) {
      commit("failure", error);
    }
  },
  async verifyPassword({ commit }, token) {
    try {
      commit("wait");
      const verifyPassword = httpsCallable(functions, "verifyPassword");
      const res = await verifyPassword({ token });
      if (!res.data.success) {
        commit("failure", "verification_failed");
        return;
      }

      commit("setSuccess", "password_updated");
      commit("done");
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  done(state) {
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setUser(state, user) {
    state.user = user;
    i18n.global.locale.value = user.language;
    localStorage.setItem("vetera-language", user.language);
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const authentication = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default authentication;
