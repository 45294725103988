import { auth } from "@/firebase";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [],
    },
  },
  {
    path: "/branding",
    name: "Branding",
    component: () => import("../views/Branding.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["branding"],
    },
  },
  {
    path: "/bulletins",
    name: "Bulletins",
    component: () => import("../views/Bulletins.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["bulletins"],
    },
  },
  {
    path: "/bulletins/create",
    name: "Bulletin create",
    component: () => import("../views/BulletinCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["bulletins", "create"],
    },
  },
  {
    path: "/bulletins/:bulletinId",
    name: "Bulletin",
    component: () => import("../views/Bulletin.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["bulletins", "bulletin"],
    },
  },
  {
    path: "/bulletins/:bulletinId/edit",
    name: "Bulletin Edit",
    component: () => import("../views/BulletinEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["bulletins", "bulletin", "edit"],
    },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("../views/Documents.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["documents"],
    },
  },
  {
    path: "/documents/:documentId",
    name: "Document",
    component: () => import("../views/Document.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["documents", "document"],
    },
  },
  {
    path: "/documents/create",
    name: "Document create",
    component: () => import("../views/DocumentCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["documents", "create"],
    },
  },
  {
    path: "/documents/:documentId/edit",
    name: "Document edit",
    component: () => import("../views/DocumentEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["documents", "document", "edit"],
    },
  },
  {
    path: "/estates",
    name: "Estates",
    component: () => import("../views/Estates.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates"],
    },
  },
  {
    path: "/estates/create",
    name: "Estate create",
    component: () => import("../views/EstateCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "create"],
    },
  },
  {
    path: "/estates/:estateId",
    name: "Estate",
    component: () => import("../views/Estate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate"],
    },
  },
  {
    path: "/estates/:estateId/edit",
    name: "Estate edit",
    component: () => import("../views/EstateEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "edit"],
    },
  },
  {
    path: "/estates/:estateId/contacts/edit",
    name: "Estate contacts edit",
    component: () => import("../views/EstateContactsEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "contacts", "edit"],
    },
  },
  {
    path: "/estates/:estateId/documents/:documentId",
    name: "Estate document",
    component: () => import("../views/Document.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "documents", "document"],
    },
  },
  {
    path: "/estates/:estateId/partners/edit",
    name: "Estate partners edit",
    component: () => import("../views/EstatePartnersEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "partner_companies", "manage"],
    },
  },
  {
    path: "/estates/:estateId/keys/create",
    name: "Estate key create",
    component: () => import("../views/KeyCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "keys", "create"],
    },
  },
  {
    path: "/estates/:estateId/keys/:keyId",
    name: "Estate key",
    component: () => import("../views/Key.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "keys", "key"],
    },
  },
  {
    path: "/estates/:estateId/keys/:keyId/edit",
    name: "Estate key edit",
    component: () => import("../views/KeyEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "keys", "key", "edit"],
    },
  },
  {
    path: "/estates/:estateId/notifications/:notificationId",
    name: "Estate notification",
    component: () => import("../views/Notification.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "notifications", "notification"],
    },
  },
  {
    path: "/estates/:estateId/notifications/:notificationId/tasks/:taskId",
    name: "Estate request task",
    component: () => import("../views/Task.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [
        "estates",
        "estate",
        "notifications",
        "notification",
        "tasks",
        "task",
      ],
    },
  },
  {
    path: "/estates/:estateId/notifications/:notificationId/tasks/create",
    name: "Estate request task create",
    component: () => import("../views/TaskCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [
        "estates",
        "estate",
        "notifications",
        "notification",
        "tasks",
        "create",
      ],
    },
  },
  {
    path: "/estates/:estateId/notifications/:notificationId/tasks/:taskId/edit",
    name: "Estate request task edit",
    component: () => import("../views/TaskEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [
        "estates",
        "estate",
        "notifications",
        "notification",
        "tasks",
        "task",
        "edit",
      ],
    },
  },
  {
    path: "/estates/:estateId/persons/create",
    name: "Estate user create",
    component: () => import("../views/UserCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "persons", "create"],
    },
  },
  {
    path: "/estates/:estateId/persons/:userId",
    name: "Estate user",
    component: () => import("../views/User.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "persons", "user"],
    },
  },
  {
    path: "/estates/:estateId/persons/:userId/edit",
    name: "Estate user edit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate", "persons", "user", "edit"],
    },
  },
  {
    path: "/files",
    name: "Files",
    component: () => import("../views/Files.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["files"],
    },
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
  {
    path: "/keys",
    name: "Keys",
    component: () => import("../views/Keys.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys"],
    },
  },
  {
    path: "/keys/create",
    name: "Key create",
    component: () => import("../views/KeyCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys", "create"],
    },
  },
  {
    path: "/keys/:keyId",
    name: "Key",
    component: () => import("../views/Key.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys", "key"],
    },
  },
  {
    path: "/keys/:keyId/edit",
    name: "Key edit",
    component: () => import("../views/KeyEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys", "key", "edit"],
    },
  },
  {
    path: "/keys/:keyId/lend",
    name: "Key lend",
    component: () => import("../views/KeyLend.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys", "key", "lend"],
    },
  },
  {
    path: "/keys/:keyId/loan/:loanId",
    name: "Key loan",
    component: () => import("../views/KeyLoan.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys", "key", "loan"],
    },
  },
  {
    path: "/keys/:keyId/return",
    name: "Key return",
    component: () => import("../views/KeyReturn.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["keys", "key", "return"],
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Notifications.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["notifications"],
    },
  },
  {
    path: "/notifications/create",
    name: "Notification create",
    component: () => import("../views/NotificationCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["notifications", "create"],
    },
  },
  {
    path: "/notifications/:notificationId",
    name: "notification",
    component: () => import("../views/Notification.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["notification"],
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Orders.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["orders"],
    },
  },
  {
    path: "/orders/:orderId",
    name: "Order",
    component: () => import("../views/Order.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["orders", "order"],
    },
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Partners.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partner_companies"],
    },
  },
  {
    path: "/partners/:partnerId",
    name: "Partner company",
    component: () => import("../views/Partner.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partner_companies", "partner_company"],
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile"],
    },
  },
  {
    path: "/profile/auth",
    name: "Profile auth edit",
    component: () => import("../views/ProfileEditAuth.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile", "auth"],
    },
  },
  {
    path: "/profile/edit",
    name: "Profile edit",
    component: () => import("../views/ProfileEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile", "edit"],
    },
  },
  {
    path: "/personnel",
    name: "Personnel",
    component: () => import("../views/Personnel.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel"],
    },
  },
  {
    path: "/personnel/create",
    name: "User create",
    component: () => import("../views/UserCreate.vue"),
    meta: {
      adminRequired: true,
      authRequired: true,
      breadcrumb: ["personnel", "create"],
    },
  },
  {
    path: "/personnel/:userId",
    name: "User",
    component: () => import("../views/User.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel", "user"],
    },
  },
  {
    path: "/personnel/:userId/edit",
    name: "User edit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      adminRequired: true,
      authRequired: true,
      breadcrumb: ["personnel", "user", "edit"],
    },
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["products"],
    },
  },
  {
    path: "/products/:productId",
    name: "Product",
    component: () => import("../views/Product.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["products", "product"],
    },
  },
  {
    path: "/products/create",
    name: "Product create",
    component: () => import("../views/ProductCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["products", "create"],
    },
  },
  {
    path: "/products/:productId",
    name: "Product",
    component: () => import("../views/Product.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["products", "product"],
    },
  },
  {
    path: "/products/:productId/edit",
    name: "Product edit",
    component: () => import("../views/ProductEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["products", "product", "edit"],
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects"],
    },
  },
  {
    path: "/projects/create",
    name: "Project create",
    component: () => import("../views/ProjectCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "create"],
    },
  },
  {
    path: "/projects/:projectId",
    name: "Project",
    component: () => import("../views/Project.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project"],
    },
  },
  {
    path: "/projects/:projectId/edit",
    name: "Project edit",
    component: () => import("../views/ProjectEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "edit"],
    },
  },
  {
    path: "/projects/:projectId/phases",
    name: "Project phases",
    component: () => import("../views/Phases.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phases"],
    },
  },
  {
    path: "/projects/:projectId/phases/:phaseId",
    name: "Project phase",
    component: () => import("../views/Phase.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phases", "phase"],
    },
  },
  {
    path: "/projects/:projectId/phases/create",
    name: "Project phase create",
    component: () => import("../views/PhaseCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phases", "create"],
    },
  },
  {
    path: "/projects/:projectId/phases/:phaseId/edit",
    name: "Project phase edit",
    component: () => import("../views/PhaseEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phases", "phase", "edit"],
    },
  },
  {
    path: "/projects/:projectId/:phaseName/create-task",
    name: "Project task create",
    component: () => import("../views/TaskCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phase", "create_task"],
    },
  },
  {
    path: "/projects/:projectId/:phaseName/:taskId",
    name: "Project task",
    component: () => import("../views/Task.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phase", "task"],
    },
  },
  {
    path: "/projects/:projectId/:phaseName/:taskId/edit",
    name: "Project task edit",
    component: () => import("../views/TaskEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["projects", "project", "phase", "task", "edit"],
    },
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/Tasks.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks"],
    },
  },
  {
    path: "/tasks/:taskId",
    name: "Task",
    component: () => import("../views/Task.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks", "task"],
    },
  },
  {
    path: "/tasks/create",
    name: "Task create",
    component: () => import("../views/TaskCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks", "create"],
    },
  },
  {
    path: "/tasks/:taskId/edit",
    name: "Task edit",
    component: () => import("../views/TaskEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks", "task", "edit"],
    },
  },
  {
    path: "/tasks/:taskId/notes/create",
    name: "Task note create",
    component: () => import("../views/TaskNoteCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks", "task", "notes", "create"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await currentUser?.getIdTokenResult();
  if (to.meta.authRequired && !currentUser) {
    return next("/sign-in");
  } else if (to.meta.authRequired && idTokenResult?.claims.password_reset) {
    return next("/complete");
  } else {
    return next();
  }
});

export default router;
