export const getAvatar = (user) => {
  return `https://storage.googleapis.com/vetera-a1105.appspot.com/users/${user.id}/${user.avatar}`;
};

export const getEstateImage = (estate) => {
  if (!estate.image) return "/assets/background.png";
  return `https://storage.googleapis.com/vetera-a1105.appspot.com/companies/${estate.companyId}/estates/${estate.id}/${estate.image}`;
};

export const getLogo = (company) => {
  if (!company.logo) return "/assets/logo-dark.png";
  return `https://storage.googleapis.com/vetera-a1105.appspot.com/companies/${company.id}/${company.logo}`;
};
