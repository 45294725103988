export const hexToHue = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  let h;
  (r /= 255), (g /= 255), (b /= 255);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  if (max - min == 0) {
    return 0;
  }

  if (max == r) {
    h = (g - b) / (max - min);
  } else if (max == g) {
    h = 2 + (b - r) / (max - min);
  } else if (max == b) {
    h = 4 + (r - g) / (max - min);
  }

  h = h * 60;
  h %= 360;
  if (h < 0) {
    h += 360;
  }

  return Math.round(h);
};

export const pixelRGB = (pixel) => {
  const r = pixel[0].toString(16);
  const g = pixel[1].toString(16);
  const b = pixel[2].toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};
