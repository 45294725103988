import { db, functions } from "@/firebase";
import router from "@/router";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    projectTasks: [],
    requestTasks: [],
    task: {},
    tasks: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async addFile({ commit }, data) {
    try {
      commit("wait");
      const addFile = httpsCallable(functions, "addFile");
      const res = await addFile(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "task_updated");
      commit("replaceTask", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async addImage({ commit }, data) {
    try {
      commit("wait");
      const addImage = httpsCallable(functions, "addImage");
      const res = await addImage(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "task_updated");
      commit("replaceTask", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createTask({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "tasks"), data);
      const task = data;
      task.id = docRef.id;

      commit("setSuccess", "task_created");
      commit("addTask", task);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateTask({ commit }, { data, taskId }) {
    try {
      commit("wait");
      const docRef = doc(db, "tasks", taskId);
      await updateDoc(docRef, data);
      const task = data;
      commit("setSuccess", "task_updated");
      commit("replaceTask", task);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getNotificationTask({ commit }, notificationId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "tasks"),
        where("requestId", "==", notificationId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map((doc) => {
        const task = doc.data();
        task.id = doc.id;
        return task;
      });

      commit("setTask", tasks[0]);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getTask({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "tasks", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const task = docSnap.data();
        task.id = docSnap.id;
        commit("setTask", task);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getTasks({ commit }, companyId) {
    try {
      commit("wait");
      const getTasks = httpsCallable(functions, "getCompanyTasks");
      const res = await getTasks({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setTasks", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createProjectTask(
    { commit, dispatch },
    { data, projectId, phaseName }
  ) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "tasks"), data);
      const task = data;
      task.id = docRef.id;

      commit("setSuccess", "task_created");
      commit("addTask", task);
      dispatch(
        "project/addPhaseTask",
        { taskId: task.id, projectId, phaseName },
        {
          root: true,
        }
      );
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async getProjectTasks({ commit }, taskIds) {
    try {
      commit("wait");
      const q = query(
        collection(db, "tasks"),
        where(documentId(), "in", taskIds)
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map((doc) => {
        const task = doc.data();
        task.id = doc.id;
        return task;
      });
      commit("setProjectTasks", tasks);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getRequestTasks({ commit }, requestId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "tasks"),
        where("requestId", "==", requestId)
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map((doc) => {
        const task = doc.data();
        task.id = doc.id;
        return task;
      });

      commit("setRequestTasks", tasks);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createNote({ commit }, { data, taskId }) {
    try {
      commit("wait");
      const docRef = doc(db, "tasks", taskId);
      await updateDoc(docRef, { notes: arrayUnion(data) });
      const task = data;
      commit("setSuccess", "note_created");
      commit("replaceTask", task);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async saveTimes({ commit }, tasks) {
    try {
      commit("wait");
      const saveTimes = httpsCallable(functions, "saveTimes");
      const res = await saveTimes({ tasks });

      if (!res.data.success) throw "unknown";

      commit("setTasks", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addTask(state, task) {
    state.tasks.push(task);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceTask(state, task) {
    const index = state.tasks.findIndex((b) => b.id == task.id);
    if (index != -1) {
      state.tasks.splice(index, 1, task);
    }
    state.task = task;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setTask(state, task) {
    state.task = task;
    state.waiting = false;
  },
  setTasks(state, tasks) {
    state.tasks = tasks;
    state.waiting = false;
  },
  setProjectTasks(state, tasks) {
    state.projectTasks = tasks;
    state.waiting = false;
  },
  setRequestTasks(state, tasks) {
    state.requestTasks = tasks;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getTask(state) {
    return state.task;
  },
};

const task = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default task;
