import { db, functions, storage } from "@/firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref } from "firebase/storage";

const getDefaultState = () => {
  return {
    file: null,
    files: [],
    fileTree: {},
    status: {},
    tags: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createFile({ commit }, data) {
    try {
      commit("wait");
      const createFile = httpsCallable(functions, "createFile");
      const res = await createFile(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "file_created");
      commit("setFileTree", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createFolder({ commit }, data) {
    try {
      commit("wait");
      const createFolder = httpsCallable(functions, "createFolder");
      const res = await createFolder(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "folder_created");
      commit("setFileTree", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstateFiles({ commit }, data) {
    try {
      commit("wait");
      const getEstateFiles = httpsCallable(functions, "getEstateFiles");
      const res = await getEstateFiles(data);

      if (!res.data.success) throw "unknown";

      commit("setFiles", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getFile({ commit }, path) {
    commit("wait");
    commit("setFile", null);
    try {
      const downloadUrl = await getDownloadURL(ref(storage, path));
      commit("setFile", downloadUrl);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getFileTree({ commit }, companyId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "files"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);

      let fileTree = {};
      if (querySnapshot.empty) {
        const doc = await addDoc(collection(db, "files"), { companyId });
        fileTree.id = doc.id;
      } else {
        fileTree = querySnapshot.docs[0].data();
        fileTree.id = querySnapshot.docs[0].id;
      }

      commit("setFileTree", fileTree);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getTags({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "tags"));
      const tags = querySnapshot.docs.map((doc) => {
        const tag = doc.data();
        tag.id = doc.id;
        return tag;
      });
      commit("setTags", tags);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateFolder({ commit }, data) {
    try {
      commit("wait");
      const updateFolder = httpsCallable(functions, "updateFolder");
      const res = await updateFolder(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "folder_updated");
      commit("setFileTree", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addFile(state, file) {
    state.files.push(file);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setFile(state, file) {
    state.file = file;
    state.waiting = false;
  },
  setFiles(state, files) {
    state.files = files;
    state.waiting = false;
  },
  setFileTree(state, fileTree) {
    state.fileTree = fileTree;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setTags(state, tags) {
    state.tags = tags;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const file = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default file;
