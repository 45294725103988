import { db, functions } from "@/firebase";
import router from "@/router";
import {
  addDoc,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    estate: {},
    estates: [],
    partnerEstates: [],
    productEstates: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async addPartner({ commit }, { estateId, partnerId }) {
    try {
      commit("wait");
      const addPartnerToEstate = httpsCallable(functions, "addPartnerToEstate");
      const res = await addPartnerToEstate({ estateId, partnerId });

      if (!res.data.success) throw "unknown";
      commit("setSuccess", "partner_added");
      commit("setEstate", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createEstate({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "estates"), data);
      const estate = data;
      estate.id = docRef.id;

      commit("setSuccess", "estate_created");
      commit("addEstate", estate);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstate({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "estates", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const estate = docSnap.data();
        estate.id = docSnap.id;
        commit("setEstate", estate);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstates({ commit }, companyId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "estates"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);
      const estates = querySnapshot.docs.map((doc) => {
        const estate = doc.data();
        estate.id = doc.id;
        return estate;
      });

      commit("setEstates", estates);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPartnerEstates({ commit }, { companyId, partnerId }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "estates"),
        where("companyId", "==", companyId),
        where("partnerIds", "array-contains", partnerId)
      );
      const querySnapshot = await getDocs(q);
      const estates = querySnapshot.docs.map((doc) => {
        const estate = doc.data();
        estate.id = doc.id;
        return estate;
      });

      commit("setPartnerEstates", estates);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getProductEstates({ commit }, estateIds) {
    try {
      commit("wait");
      const q = query(
        collection(db, "estates"),
        where(documentId(), "in", estateIds)
      );
      const querySnapshot = await getDocs(q);
      const estates = querySnapshot.docs.map((doc) => {
        const estate = doc.data();
        estate.id = doc.id;
        return estate;
      });

      commit("setProductEstates", estates);
    } catch (error) {
      commit("failure", error);
    }
  },
  async removePartner({ commit }, { estateId, partnerId }) {
    try {
      commit("wait");
      const removePartnerFromEstate = httpsCallable(
        functions,
        "removePartnerFromEstate"
      );
      const res = await removePartnerFromEstate({ estateId, partnerId });

      if (!res.data.success) throw "unknown";
      commit("setSuccess", "partner_removed");
      commit("setEstate", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async syncFivaldi({ commit }) {
    try {
      commit("wait");
      const syncFivaldi = httpsCallable(functions, "syncFivaldi");
      const res = await syncFivaldi();

      if (!res.data.success) throw "unknown";
      commit("setSuccess", "fivaldi_synced");
      commit("addEstates", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateEstate({ commit }, data) {
    try {
      commit("wait");
      const updateEstate = httpsCallable(functions, "updateEstate");
      const res = await updateEstate(data);
      if (!res.data.success) throw "unknown";

      commit("setSuccess", "estate_updated");
      commit("replaceEstate", res.data.data);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateEstateContacts({ commit }, { id, data }) {
    try {
      commit("wait");
      const docRef = doc(db, "estates", id);
      await updateDoc(docRef, data);
      const estate = data;
      estate.id = id;

      commit("setSuccess", "contacts_updated");
      commit("replaceContacts", { id, data });
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateImage({ commit }, data) {
    try {
      commit("wait");
      const updateImage = httpsCallable(functions, "updateEstateImage");
      const res = await updateImage(data);
      if (!res.data.success) {
        commit("failure", "estate_image_update_failed");
        return;
      }

      commit("setSuccess", "estate_updated");
      commit("replaceEstate", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addEstate(state, estate) {
    state.estates.push(estate);
    state.waiting = false;
  },
  addEstates(state, estates) {
    for (const estate of estates) {
      state.estates.push(estate);
    }
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceContacts(state, { id, data }) {
    const index = state.estates.findIndex((e) => e.id == id);
    if (index != -1) {
      const estate = state.estates[index];
      Object.assign(estate, data);
      state.estates.splice(index, 1, estate);
      state.estate = estate;
    }
    state.waiting = false;
  },
  replaceEstate(state, estate) {
    const index = state.estates.findIndex((e) => e.id == estate.id);
    if (index != -1) {
      state.estates.splice(index, 1, estate);
    }
    state.estate = estate;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setEstate(state, estate) {
    state.estate = estate;
    state.waiting = false;
  },
  setEstates(state, estates) {
    state.estates = estates;
    state.waiting = false;
  },
  setPartnerEstates(state, estates) {
    state.partnerEstates = estates;
    state.waiting = false;
  },
  setProductEstates(state, estates) {
    state.productEstates = estates;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getEstate(state) {
    return state.estate;
  },
};

const estate = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default estate;
