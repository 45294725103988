import { db, functions } from "@/firebase";
import router from "@/router";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    key: {},
    keys: [],
    loan: {},
    loans: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createKey({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "keys"), data);
      const key = data;
      key.id = docRef.id;

      commit("setSuccess", "key_created");
      commit("addKey", key);
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstateKeys({ commit }, estateId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "keys"),
        where("estateId", "==", estateId)
      );
      const querySnapshot = await getDocs(q);
      const keys = querySnapshot.docs.map((doc) => {
        const key = doc.data();
        key.id = doc.id;
        return key;
      });

      commit("setKeys", keys);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getKey({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "keys", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const key = docSnap.data();
        key.id = docSnap.id;
        commit("setKey", key);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getKeyLoan({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "loans", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const loan = docSnap.data();
        loan.id = docSnap.id;
        commit("setLoan", loan);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getKeyLoans({ commit }, keyId) {
    try {
      commit("wait");
      const q = query(collection(db, "loans"), where("keyId", "==", keyId));
      const querySnapshot = await getDocs(q);
      const loans = querySnapshot.docs.map((doc) => {
        const loan = doc.data();
        loan.id = doc.id;
        return loan;
      });

      commit("setLoans", loans);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getKeys({ commit }, companyId) {
    try {
      commit("wait");
      const getKeys = httpsCallable(functions, "getCompanyKeys");
      const res = await getKeys({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setKeys", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async lendKey({ commit }, data) {
    try {
      commit("wait");
      const lendKey = httpsCallable(functions, "lendKey");
      const res = await lendKey(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "key_lent");
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async returnKey({ commit }, data) {
    try {
      commit("wait");
      const returnKey = httpsCallable(functions, "returnKey");
      const res = await returnKey(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "key_returned");
      router.go(-1);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateKey({ commit }, { data, keyId }) {
    try {
      commit("wait");
      const docRef = doc(db, "keys", keyId);
      await updateDoc(docRef, data);
      const key = data;
      key.id = keyId;
      commit("setSuccess", "key_updated");
      commit("replaceKey", key);
      router.push("/keys/" + keyId);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addKey(state, key) {
    state.keys.push(key);
    state.waiting = false;
  },
  addLoan(state, loan) {
    state.loans.push(loan);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceKey(state, key) {
    const index = state.keys.findIndex((k) => k.id == key.id);
    if (index != -1) {
      state.keys.splice(index, 1, key);
    }
    state.key = key;
    state.waiting = false;
  },
  replaceLoan(state, loan) {
    const index = state.loans.findIndex((l) => l.id == loan.id);
    if (index != -1) {
      state.loans.splice(index, 1, loan);
    }
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setKey(state, key) {
    state.key = key;
    state.waiting = false;
  },
  setKeys(state, keys) {
    state.keys = keys;
    state.waiting = false;
  },
  setLoan(state, loan) {
    state.loan = loan;
    state.waiting = false;
  },
  setLoans(state, loans) {
    state.loans = loans;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getKey(state) {
    return state.key;
  },
};

const key = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default key;
