<template>
  <div class="flex w-full flex-col border-t bg-white">
    <navigation-item
      v-for="item in items"
      :company="company"
      :key="item.index"
      :item="item"
      @click="navigateTo(item)"
    />
  </div>
</template>

<script>
import {
  BellIcon,
  BuildingOffice2Icon,
  CubeIcon,
  CurrencyEuroIcon,
  DocumentIcon,
  FolderIcon,
  HomeIcon,
  KeyIcon,
  ListBulletIcon,
  MegaphoneIcon,
  PaintBrushIcon,
  RocketLaunchIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/vue/24/outline";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import NavigationItem from "./NavigationItem.vue";
export default {
  components: { NavigationItem },
  props: {
    company: {
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const items = reactive([
      { icon: HomeIcon, label: "home", path: "/" },
      {
        icon: BuildingOffice2Icon,
        label: "estates",
        path: "/estates",
      },
      {
        icon: KeyIcon,
        label: "keys",
        path: "/keys",
      },
      {
        icon: WrenchScrewdriverIcon,
        label: "partner_companies",
        path: "/partners",
      },
      {
        icon: BellIcon,
        label: "notifications",
        path: "/notifications",
      },
      {
        icon: MegaphoneIcon,
        label: "bulletins",
        path: "/bulletins",
      },
      {
        icon: RocketLaunchIcon,
        label: "projects",
        path: "/projects",
      },
      {
        icon: ListBulletIcon,
        label: "tasks",
        path: "/tasks",
      },
      {
        icon: DocumentIcon,
        label: "documents",
        path: "/documents",
      },
      {
        icon: FolderIcon,
        label: "files",
        path: "/files",
      },
      {
        divider: true,
      },
      {
        icon: CurrencyEuroIcon,
        label: "orders",
        path: "/orders",
      },
      {
        icon: CubeIcon,
        label: "products",
        path: "/products",
      },
      {
        icon: UsersIcon,
        label: "personnel",
        path: "/personnel",
      },
      {
        icon: PaintBrushIcon,
        label: "branding",
        path: "/branding",
      },
    ]);

    const navigateTo = (item) => {
      router.push(item.path);
    };

    return {
      items,
      navigateTo,
    };
  },
};
</script>
