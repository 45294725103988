<template>
  <loading-overlay v-if="route.meta.authRequired && !company.id" />
  <div
    v-else
    class="min-h-full bg-background bg-cover bg-fixed"
    :class="{ 'h-full': !route.meta.authRequired }"
  >
    <navigation-menu v-if="route.meta.authRequired" />
    <div
      v-if="route.meta.authRequired && !validLicence"
      class="h-full pt-24 md:ml-72"
    >
      <no-licence />
    </div>
    <router-view
      v-else
      :class="route.meta.authRequired ? 'pt-24 md:ml-72' : null"
      class="h-full"
    />
    <div class="absolute top-6 right-6 flex flex-col gap-2">
      <toast
        v-for="toast in toasts"
        :key="toast.id"
        :content="toast.content"
        :id="toast.id"
        :life="toast.life"
        :severity="toast.severity"
        @destroy="destroyToast"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import LoadingOverlay from "./components/loading/LoadingOverlay.vue";
import NavigationMenu from "./components/navigation/NavigationMenu.vue";
import Toast from "./components/toast/Toast.vue";
import NoLicence from "./views/NoLicence.vue";
export default {
  components: { LoadingOverlay, NavigationMenu, NoLicence, Toast },
  setup() {
    const route = useRoute();
    const store = useStore();

    const validLicence = computed(
      () => store.getters["company/getLicenceValidity"]
    );

    const counter = ref(0);
    const toasts = ref([]);

    const destroyToast = (id) => {
      const index = toasts.value.findIndex((toast) => toast.id == id);
      if (index != -1) {
        toasts.value.splice(index, 1);
      }
    };

    watch(
      () => store.state.authentication.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 3000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.bulletin.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.company.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.document.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.estate.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.file.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.key.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.notification.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.order.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.partner.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.product.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.project.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.role.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.task.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.user.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    return {
      company: computed(() => store.state.company.company),
      destroyToast,
      route,
      toasts,
      validLicence,
    };
  },
};
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

html,
body,
#app {
  height: 100%;
}
</style>
